import React, { useState, useEffect } from 'react';

const BackUpButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleBackUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const checkScroll = () => {
            if (!isVisible && window.pageYOffset > 200) {
                setIsVisible(true);
            } else if (isVisible && window.pageYOffset <= 200) {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', checkScroll);
        return () => window.removeEventListener('scroll', checkScroll);
    }, [isVisible]);

    return isVisible ? (
        <button
            className="fixed bottom-5 right-5 bg-blue-400 border-2 rounded-full w-12 h-12 flex items-center justify-center"
            onClick={handleBackUp}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
        </button>
    ) : null;
};

export default BackUpButton;