import React from 'react';

export const KerberusLied = () => {
    return (
        <div className="flex flex-col items-center snap-normal phoneMargins">
            <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Kerberus Lied</h1>
            </div>
            <div className="flex flex-col sm:flex-row items-center mt-20">
                <img
                    src="/Atmosphere/KerberusSfeer3.png"
                    alt="Kerberus Lied"
                    className="w-full sm:w-1/2"
                />
                <div className="flex flex-col justify-center w-full sm:w-1/2">
                    <p className="text-2xl mt-4 text-center max-w-md mx-auto">
                        Waar in 't Diepenbeeks heideland
                        't KHLimmerslied weerklinkt
                        En de brede Demerstroom
                        door de vlakte vloeit,
                        zijn studenten vrij en blij
                        als Kerberus troont.
                        Daar is mijn studentenland
                        Diepenbeeks dierbaar oord (bis)
                        Waar ook ieder goed student
                        steeds een liefje vindt
                        en ook elk Kerberuslid
                        vaak zn pintje drinkt.
                    </p>
                </div>
            </div>
        </div>
    );
};