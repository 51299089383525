import React from 'react';

const Footer = () => {
    return (
        <footer className='h-20 bg-slate-100 flex items-center justify-center'>
            <div className="footer-content">
                <div className="text-slate-400">
                    © Kerberus Diepenbeek 2023-2024
                </div>
            </div>
        </footer>
    );
};

export default Footer;