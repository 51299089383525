import PraesidiumList from "../components/Praesidium/PraesidiumList";


const Praesidium = () => {
    return (
        <div class="bg-white">
            <div class="relative isolate px-6 pt-14 lg:px-8">
                <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                  <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
                </div>
                <div class="mx-auto max-w-2xl pt-32 pb-20 scroll-pb-40 sm:pt-48 sm:pb-32 lg:pt-56">
                    <div class="text-center">
                        <h1 class="text-4xl font-bold tracking-tight underline text-black-700 sm:text-6xl">PRAESIDIUM</h1>
                    </div>
                    
                </div>
                <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
                </div>
            </div>
            <PraesidiumList></PraesidiumList>
            <div className="flex justify-start text-center text-2xl ml-10 mt-10">
                <a className="hover:text-blue-400" href="/oudPraesidium">Oud praesidium <span aria-hidden="true">&rarr;</span></a>
            </div>
        </div>
        
    )
}

export default Praesidium;