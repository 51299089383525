import { PhoneIcon, EnvelopeIcon, HomeIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: '+32 468 28 23 26',
    description:
      '',
    icon: PhoneIcon,
  },
  {
    name: 'praeses@kerberus.be',
    description: '',
    icon: EnvelopeIcon,
  },
  {
    name: 'Agoralaan, gebouw B bus 1 3590 Diepenbeek',
    description: '',
    icon: HomeIcon,
  },
]

export default function Contact() {
  return (
    <div class="bg-white pt-36 pb-20">
      <div class="relative isolate px-6 pt-28 lg:px-8">
        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">  
                Voor vragen kan je ons contacteren via deze wegen:  
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-blue-500" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <iframe
                className="w-full h-full object-cover"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.7280003103147!2d5.3953584!3d50.928748399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c12057c65cb1fd%3A0xf90b112dd95b9e1!2sUniversity%20College%20Leuven-Limburg%20-%20Campus%20Diepenbeek!5e0!3m2!1sen!2sbe!4v1713623477827!5m2!1sen!2sbe"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
            ></iframe>
          </div>
          
        </div>
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
          <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
        </div>
      </div>
    </div>

  )
}
