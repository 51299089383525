import './App.css';
import Main from './Main';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SponsorComponent from './components/Homepage/Sponsors';
import BackUpButton from './components/BackUpButton';

function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <Main></Main>
      <SponsorComponent></SponsorComponent>
      <Footer></Footer>
      <BackUpButton></BackUpButton>
    </div>
  );
}

export default App;
