const bestuursleden = [
    {
        jaar: "2022-2023",
        leden: [
            { positie: "Praeses", naam: "Stef Lipkens (Breer)" },
            { positie: "Vice-Praeses", naam: "Jorrit Moons" },
            { positie: "Vice-Praeses", naam: "Fabian Dirix" },
            { positie: "Quaestor", naam: "Merel Vanhoof" },
            { positie: "Ab-Actis", naam: "Sander Rutten" },
            { positie: "Schachtenmeester", naam: "Nona Vanratingen" },
            { positie: "Schachtenmeester", naam: "Bram Kauffmann" },
            { positie: "Schachtenmeester", naam: "Barend Casier" },
            { positie: "PR", naam: "Margot Judong" },
            { positie: "PR", naam: "Nick De Pauw" },
            { positie: "TN", naam: "Esmee Persijn (Soux)" },
            { positie: "TN", naam: "Daryll Geven" },
            { positie: "Dux Festi", naam: "Femke Derison" },
            { positie: "Dux Festi", naam: "Jana Luyks" },
            { positie: "Media", naam: "Brent Willems (Krim)" },
            { positie: "Media", naam: "Tom Duym (Alabama)" },
            { positie: "Bacchus", naam: "Isabelle Willems" },
            { positie: "Bacchus", naam: "Kiara Liwerski" },
            { positie: "Cultuur", naam: "Dimitri Scheepmans" },
            // Eventuele andere posities hier toevoegen
        ]
    },
    {
        jaar: "2021-2022",
        leden: [
            { positie: "Praeses", naam: "Elewout Swinnen" },
            { positie: "Vice-Praeses", naam: "Stef Lipkens (Breer)" },
            { positie: "Vice-Praeses", naam: "Toon Vekemans" },
            { positie: "Quaestor", naam: "Robin Voorpijl" },
            { positie: "Ab-Actis", naam: "Femke Boelen" },
            { positie: "Schachtenmeester", naam: "Flor Huisman (Taco)" },
            { positie: "Schachtenmeester", naam: "Isabelle Willems" },
            { positie: "Schachtenmeester", naam: "Kyara Jeunen" },
            { positie: "PR", naam: "Merel Vanhoof" },
            { positie: "PR", naam: "Fabian Dirix" },
            { positie: "TN", naam: "Esmee Persijn (Soux)" },
            { positie: "TN", naam: "Kiara Liwerski" },
            { positie: "Dux Festi", naam: "Sander Rutten" },
            { positie: "Dux Festi", naam: "Jorrit Moons" },
            { positie: "Media", naam: "Chiara Derickx" },
            { positie: "Bacchus", naam: "Tom Duym (Alabama)" }
        ]
    },
    {
        jaar: "2020-2021",
        leden: [
            { positie: "Praeses", naam: "Jovi Janssens" },
            { positie: "Vice-Praeses", naam: "Stef Lipkens (Breer)" },
            { positie: "Vice-Praeses", naam: "Elewout Swinnen" },
            { positie: "Quaestor", naam: "Perla Claesen" },
            { positie: "Ab-Actis", naam: "Femke Boelen" },
            { positie: "Schachtenmeester", naam: "Tom Duym (Alabama)" },
            { positie: "Schachtenmeester", naam: "Wietse Sapion" },
            { positie: "Schachtenmeester", naam: "Isabelle Willems" },
            { positie: "Schachtenmeester", naam: "Kyara Jeunen" },
            { positie: "PR", naam: "Toon Vekemans" },
            { positie: "PR", naam: "Robin Voorpijl" },
            { positie: "TN", naam: "Esmee Persijn" },
            { positie: "TN", naam: "Merel Vanhoof" },
            { positie: "Dux Festi", naam: "Sander Rutten" },
            { positie: "Dux Festi", naam: "Jorrit Moons" },
            { positie: "Media", naam: "Chiara Derickx" },
            { positie: "Provisor", naam: "Kiara Liwerski" }
        ]
    },
    {
        jaar: "2019-2020",
        leden: [
            { positie: "Praeses", naam: "Simon Van Hecke (Spøhk)" },
            { positie: "Vice-Praeses", naam: "Jovi Janssens" },
            { positie: "Vice-Praeses", naam: "Julian 'Cabi' Cabistan" },
            { positie: "Quaestor", naam: "Laury Van Loock (Niva)" },
            { positie: "Ab-Actis", naam: "Martijn 'Vieze' Bijnens (Becky)" },
            { positie: "Schachtenmeester", naam: "Stef Lipkens (Breer)" },
            { positie: "Schachtenmeester", naam: "Robin Van Elsen (Bett)" },
            { positie: "Schachtenmeester", naam: "Ineke Claessens" },
            { positie: "Bacchus", naam: "Sebastiaan Buyse (Kech)" },
            { positie: "PR", naam: "Perla Claesen" },
            { positie: "PR", naam: "Daan 'Wiebel' Luys" },
            { positie: "TN", naam: "Anne Gerits" },
            { positie: "TN", naam: "Victor Van Camp (Bask)" },
            { positie: "Dux Festi", naam: "Elewout Swinnen" },
            { positie: "Dux Festi", naam: "Martijn Nelissen" },
            { positie: "Media", naam: "Jordy Roumans" },
            { positie: "Provisor", naam: "Lana Ceyssens (Dwari)" },
            { positie: "Cantor", naam: "Tom Duym (Alabama)" }
        ]
    },
    {
        jaar: "2018-2019",
        leden: [
            { positie: "Praeses", naam: "Gerben Brands" },
            { positie: "Vice-Praeses", naam: "Tom Duym (Alabama)" },
            { positie: "Vice-Praeses", naam: "Jovi Janssens" },
            { positie: "Quaestor", naam: "An-laurence van Wonterghem (Boowé)" },
            { positie: "Ab-Actis", naam: "Brent 'Sigi' Meurrens" },
            { positie: "Schachtenmeester", naam: "Anne Gerits" },
            { positie: "Schachtenmeester", naam: "Sebastiaan Buyse (Kech)" },
            { positie: "Schachtenmeester", naam: "Seppe Gijbels (Marha)" },
            { positie: "Bacchus", naam: "Ewout Verhaegen (Drivd)" },
            { positie: "PR", naam: "Steffie Haex" },
            { positie: "PR", naam: "Martijn 'Vieze' Bijnens (Becky)" },
            { positie: "TN", naam: "Daan Vandebosch" },
            { positie: "TN", naam: "Dylan Beerens" },
            { positie: "Dux Festi", naam: "Simon Van Hecke (Spøhk)" },
            { positie: "Dux Festi", naam: "Julian 'Cabi' Cabistan" },
            { positie: "Provisor & Praetor", naam: "Gillian Mullens" }
        ]
    },
    {
        jaar: "2017-2018",
        leden: [
            { positie: "Praeses", naam: "Celien Monten (Fuertá)" },
            { positie: "Vice-Praeses", naam: "Sifra Geelen" },
            { positie: "Vice-Praeses", naam: "Gerben Brands" },
            { positie: "Quaestor", naam: "Arne Van De Put" },
            { positie: "Ab-Actis", naam: "Bjarne Camps (Jacky)" },
            { positie: "Schachtenmeester", naam: "An-laurence van Wonterghem" },
            { positie: "Schachtenmeester", naam: "Gert Brouwers (Oàjem)" },
            { positie: "Schachtenmeester", naam: "Tom Duym" },
            { positie: "Lustrum", naam: "Manon Peeters" },
            { positie: "Bacchus", naam: "Brent Meurrens (Sigi)" },
            { positie: "PR", naam: "Jovi Janssens" },
            { positie: "PR", naam: "Jens Braeken" },
            { positie: "TN", naam: "Stefano Ranauda" },
            { positie: "TN", naam: "Alexander Pécriaux" },
            { positie: "Provisor & Praetor", naam: "Gillian Mullens" }
        ]
    },
    {
        jaar: "2016-2017",
        leden: [
            { positie: "Praeses", naam: "Martijn Festjens" },
            { positie: "Vice-Praeses", naam: "Manon Peeters" },
            { positie: "Vice-Praeses", naam: "Mathijs Ceyssens" },
            { positie: "Quaestor", naam: "Dennis Willems" },
            { positie: "Ab-Actis", naam: "Sifra Geelen" },
            { positie: "Schachtenmeester", naam: "Celien Monten (Fuertá)" },
            { positie: "Schachtenmeester", naam: "Yoran Oliha (Calimero)" },
            { positie: "Schachtenmeester", naam: "Bjarne Camps (Jacky)" },
            { positie: "PR", naam: "Arne Van De Put" },
            { positie: "PR", naam: "Pieter Weckx (Anker)" },
            { positie: "Bacchus", naam: "Hans Eerdekens" },
            { positie: "Cantor", naam: "Ruben Koopmans" },
            { positie: "Media", naam: "Andries Ulenaers" },
            { positie: "TN", naam: "Tijs Dingenen" },
            { positie: "TN", naam: "Jordi Daenen" }, // Het lijkt erop dat Jordi Daenen tussen haakjes staat, dus ik neem aan dat hij misschien niet deel uitmaakte van het bestuur, laat me weten als dit niet het geval is.
            { positie: "Sport & Ontspanning", naam: "Niet gespecificeerd" }, // Ik heb deze positie niet gezien in de opgegeven lijst van bestuursleden
            { positie: "Provisor & Praetor", naam: "Niet gespecificeerd" } // Ik heb deze positie niet gezien in de opgegeven lijst van bestuursleden
        ]
    },
    {
        jaar: "2015-2016",
        leden: [
            { positie: "Praeses", naam: "Vincent Hoogmartens" },
            { positie: "Vice-Praeses", naam: "Joey Stevens (Polar)" },
            { positie: "Vice-Praeses", naam: "Dennis Willems" },
            { positie: "Quaestor", naam: "Julie Linsen" },
            { positie: "Ab-Actis", naam: "Lotte Meers" },
            { positie: "Schachtenmeester", naam: "Mathijs Ceyssens" },
            { positie: "Schachtenmeester", naam: "Pieter Weckx (Anker)" },
            { positie: "Schachtenmeester", naam: "Manon Peeters" },
            { positie: "PR", naam: "Senne Vrijsen" },
            { positie: "PR", naam: "Martijn Festjens" },
            { positie: "Bacchus", naam: "Alex Hardy" },
            { positie: "Cantor", naam: "Dennis Litjens" },
            { positie: "Media", naam: "Axel Bekx (Blije)" },
            { positie: "Vertor", naam: "Ruben Koopmans" },
            { positie: "Sport & Ontspanning", naam: "Niet gespecificeerd" }, // Ik heb deze positie niet gezien in de opgegeven lijst van bestuursleden
            { positie: "Provisor & Praetor", naam: "Jens Philtjens" }
        ]
    },
    {
        jaar: "2014-2015",
        leden: [
            { positie: "Praeses", naam: "Yannick Willekens (Dovi)" },
            { positie: "Vice-Praeses", naam: "Kris Hillen" },
            { positie: "Vice-Praeses", naam: "Joey Stevens (Polar)" },
            { positie: "Quaestor", naam: "Vincent Hoogmartens" },
            { positie: "Ab-Actis", naam: "Julie Linsen" },
            { positie: "Schachtenmeester", naam: "Hans Eerdekens" },
            { positie: "Schachtenmeester", naam: "Axel Bekx (Blije)" },
            { positie: "Schachtenmeester", naam: "Bernard Huyghe" },
            { positie: "PR", naam: "Sander Gijbels (Alpaca)" },
            { positie: "PR", naam: "Seppe Raiselis" },
            { positie: "Bacchus", naam: "Ruben Koopmans" },
            { positie: "Cantor", naam: "Julio Sanchez" },
            { positie: "Scriptor", naam: "Dennis Willems" },
            { positie: "Vertor", naam: "Jana Philippe" },
            { positie: "Sport & Ontspanning", naam: "Niet gespecificeerd" }, // Ik heb deze positie niet gezien in de opgegeven lijst van bestuursleden
            { positie: "Provisor & Praetor", naam: "Jens Philtjens" }
        ]
    },
    {
        jaar: "2013-2014",
        leden: [
            { positie: "Praeses", naam: "Alex Maris" },
            { positie: "Vice-Praeses", naam: "Niels Tamborijn" },
            { positie: "Quaestor", naam: "Yannick Willekens (Dovi)" },
            { positie: "Ab-Actis", naam: "Bernard Huyghe" },
            { positie: "Schachtenmeester", naam: "Dirk Van Gaal" },
            { positie: "Schachtenmeester", naam: "Naomi Govers" },
            { positie: "Schachtenmeester", naam: "Ben Mathijs" },
            { positie: "PR", naam: "Douwe Verhoeven" },
            { positie: "Bacchus", naam: "Jens Philtjens" },
            { positie: "Cantor", naam: "Alex Hardy" },
            { positie: "Media", naam: "Niet gespecificeerd" }, // Ik heb deze positie niet gezien in de opgegeven lijst van bestuursleden
            { positie: "TN", naam: "Brent Van Der Heyden" },
            { positie: "Sport", naam: "Pieter Van Geldorp" },
            { positie: "Ontspanning", naam: "Sander Gijbels (Alpaca)" },
            { positie: "Provisor", naam: "Kris Hillen" },
            { positie: "Praetor", naam: "Tijs Camps" }
        ]
    },
    {
        jaar: "2012-2013",
        leden: [
            { positie: "Praeses", naam: "Joren Verpoorten" },
            { positie: "Vice-Praeses", naam: "Yordi Stelten" },
            { positie: "Vice-Praeses", naam: "Jochim Kadivnik" },
            { positie: "Quaestor", naam: "Carl Claes" },
            { positie: "Ab-Actis", naam: "Tina Polleunis" },
            { positie: "Schachtenmeester", naam: "Niels Tamborijn" },
            { positie: "Schachtenmeester", naam: "Joris Tips" },
            { positie: "Schachtenmeester", naam: "Véronique Verschoren" },
            { positie: "PR", naam: "Wim Van der Aelst" },
            { positie: "Bacchus", naam: "Ben Jannis" },
            { positie: "Cantor", naam: "Jonathan Kovalevskis (Rus)" },
            { positie: "Media", naam: "Vincent Boerjan" },
            { positie: "Scriptor", naam: "Rob Mertens" },
            { positie: "TN", naam: "Lennart Camps" },
            { positie: "Sport", naam: "Nand Abrahams" },
            { positie: "Ontspanning", naam: "Filip Thaens" },
            { positie: "Praetor", naam: "Douwe Verhoeven" },
            { positie: "Praetor", naam: "Wout Verbeek" },
            { positie: "Provisor", naam: "Alex Maris" }
        ]
    },
    {
        jaar: "2011-2012",
        leden: [
            { positie: "Praeses", naam: "Thomas Bos" },
            { positie: "Vice-Praeses", naam: "Joris Tips" },
            { positie: "Quaestor", naam: "Joren Verpoorten" },
            { positie: "Ab-Actis", naam: "Kim Jorissen" },
            { positie: "Schachtenmeester", naam: "Mathijs Winters" },
            { positie: "Schachtenmeester", naam: "Remy Lauwers" },
            { positie: "PR - Sport & Ontspanning", naam: "Wannes Eerdekens" },
            { positie: "Scriptor-IT", naam: "Jeroen Van Samang" },
            { positie: "PR - Bacchus", naam: "Jochim Kadivnik" },
            { positie: "Cantor", naam: "Dries Hoeben" },
            { positie: "PR - Vertor", naam: "Eline Cuppens" },
            { positie: "Vertor", naam: "Rachel Claessens" },
            { positie: "Vertor", naam: "Gert Munters" }
        ]
    },
    {
        jaar: "2010-2011",
        leden: [
            { positie: "Praeses", naam: "Filip Smeets" },
            { positie: "Vice-Praeses", naam: "Gert Munters" },
            { positie: "Quaestor", naam: "Stefanie Meesen" },
            { positie: "Ab-Actis", naam: "Jeroen Wils" },
            { positie: "Schachtenmeester", naam: "Joris Aerts" },
            { positie: "Schachtenmeester", naam: "Roel De Jongh" },
            { positie: "Schachtenmeester", naam: "Tessa Tutteleers" },
            { positie: "PR", naam: "Stef van der Aelst" },
            { positie: "Bacchus", naam: "Dries Hoeben" },
            { positie: "Cantor", naam: "Remy Lauwers" },
            { positie: "Scriptor-IT", naam: "Thomas Bos" },
            { positie: "Vertor", naam: "Renate Spreuwers" },
            { positie: "Vertor", naam: "Sam Vanderveken" },
            { positie: "Sport & Ontspanning", naam: "Sina Zalbak" },
            { positie: "Provisor", naam: "Evy Stinkens" }
        ]
    },
    {
        jaar: "2009-2010",
        leden: [
            { positie: "Praeses", naam: "Dirk Maas" },
            { positie: "Vice-Praeses", naam: "Tim Broeders" },
            { positie: "Quaestor", naam: "Tom Claes" },
            { positie: "Ab-Actis", naam: "Paulien Myten" },
            { positie: "Schachtenmeester", naam: "Stefan Breugelmans" },
            { positie: "Schachtenmeester", naam: "Martijn Hendrikx" },
            { positie: "Schachtenmeester", naam: "Remy Lauwers" },
            { positie: "PR", naam: "Martijn van Samang" },
            { positie: "Bacchus", naam: "Rachel Claessens" },
            { positie: "Cantor", naam: "Filip Smeets" },
            { positie: "IT", naam: "Brecht Bergen" },
            { positie: "Scriptor", naam: "Ruben Vaesen" },
            { positie: "Vertor", naam: "Pieter Schuurmans" },
            { positie: "Vertor", naam: "Lynn Jaenen" },
            { positie: "Vertor", naam: "Judith Vermeij" },
            { positie: "Sport", naam: "Isabelle Gabriëls" },
            { positie: "Ontspanning", naam: "Job Huijers" },
            { positie: "Provisor", naam: "Sam Vanderveken" }
        ]
    },
    {
        jaar: "2008-2009",
        leden: [
            { positie: "Praeses", naam: "Lode Vervloesem" },
            { positie: "Vice-Praeses", naam: "Stijn Schildermans" },
            { positie: "Quaestor", naam: "Caroline Coenen" },
            { positie: "Ab-Actis/Scriptor", naam: "Laura Wienderickx" },
            { positie: "Schachtenmeester", naam: "Brecht Bergen" },
            { positie: "Schachtenmeester", naam: "Koen Kauffmann" },
            { positie: "PR", naam: "Els Rutten" },
            { positie: "PR/Cantor", naam: "Dirk Maas" },
            { positie: "Bacchus", naam: "Tom Claes" },
            { positie: "Scriptor/IT", naam: "Peter Coenen" }
        ]
    },
    {
        jaar: "2007-2008",
        leden: [
            { positie: "Praeses", naam: "Kris Kuppens" },
            { positie: "Vice-Praeses", naam: "Pieter Bex" },
            { positie: "Quaestor", naam: "Veerle Saels" },
            { positie: "Ab-Actis", naam: "Anouk Belboom" },
            { positie: "Schachtenmeester", naam: "Maarten Vandewaerde" },
            { positie: "Schachtenmeester", naam: "Raf Jaenen" },
            { positie: "PR", naam: "Andreas Vermeeren" },
            { positie: "PR", naam: "Caroline Coenen" },
            { positie: "Bacchus", naam: "Elise Cuyvers" },
            { positie: "Cantor", naam: "Bert Coenen" },
            { positie: "Scriptor", naam: "Filip Smeets" },
            { positie: "Scriptor", naam: "Peter Coenen" },
            { positie: "TN", naam: "Annick Langens" },
            { positie: "Sport & Ontspanning", naam: "Esther Vreys" },
            { positie: "Provisor", naam: "Jurgen Verley" },
            { positie: "Lustrum & Feest", naam: "Lode Vervloesem" }
        ]
    },
    {
        jaar: "2006-2007",
        leden: [
            { positie: "Praeses", naam: "Peter Stinkens" },
            { positie: "Vice-Praeses", naam: "Rob Evens" },
            { positie: "Quaestor", naam: "Charlie Geerts" },
            { positie: "Schachtenmeester", naam: "Thomas Dejan" },
            { positie: "Schachtenmeester", naam: "Jessy Goris" },
            { positie: "Schachtenmeester", naam: "Bert Coenen" },
            { positie: "PR", naam: "Joris Rutten" },
            { positie: "Bacchus", naam: "Pieter Bex" },
            { positie: "Cantor", naam: "Maarten Letent" },
            { positie: "Scriptor", naam: "Kris Kuppens" },
            { positie: "IT", naam: "Jurgen Verley" },
            { positie: "Vertor", naam: "Veerle Saels" },
            { positie: "Sport & Ontspanning", naam: "Maarten Vandewaerde" }
        ]
    },
    {
        jaar: "2005-2006",
        leden: [
            { positie: "Praeses", naam: "Niels Thijsen" },
            { positie: "Vice-Praeses", naam: "Joris Rutten" },
            { positie: "Quaestor", naam: "Jessy Goris" },
            { positie: "Ab-Actis", naam: "Thomas Dejan" },
            { positie: "Schachtenmeester", naam: "Peter Stinkens" },
            { positie: "Schachtenmeester", naam: "Charlie Geerts" },
            { positie: "Schachtenmeester", naam: "Jeroen Weckx" },
            { positie: "Bacchus", naam: "Rob Evens" },
            { positie: "Cantor", naam: "Wim Nivelle (Wereldkampioen)" },
            { positie: "Scriptor", naam: "Wout Claes" },
            { positie: "Scriptor", naam: "Karel Pouders" },
            { positie: "Scriptor", naam: "Kim Jorissen" },
            { positie: "IT", naam: "Sem Craeghs" },
            { positie: "Vertor", naam: "Pieter Bex" },
            { positie: "Vertor", naam: "Leen Van Vaerenbergh" },
            { positie: "Sport & Ontspanning", naam: "Bert Vanderwegen" },
            { positie: "Provisor", naam: "Andy Reynkens" }
        ]
    },
    {
        jaar: "2004-2005",
        leden: [
            { positie: "Praeses", naam: "Pieter Blommé" },
            { positie: "Vice-Praeses", naam: "Joris Palmans" },
            { positie: "Quaestor", naam: "Veerle Aerts" },
            { positie: "Ab-Actis", naam: "Leen Van Vaerenbergh" },
            { positie: "Schachtenmeester", naam: "Koen Geebelen" },
            { positie: "Schachtenmeester", naam: "Geert Pouders" },
            { positie: "Schachtenmeester", naam: "Kris Verjans (Klenne)" },
            { positie: "PR", naam: "Hans Wuytens" },
            { positie: "PR", naam: "Rob Vanderhenst" },
            { positie: "Bacchus", naam: "Chris Geebelen" },
            { positie: "Cantor", naam: "Stefan Van Noppen" },
            { positie: "Scriptor", naam: "Niels Thijsen" },
            { positie: "Scriptor", naam: "Els Reumers" },
            { positie: "Scriptor", naam: "Rob Evens" },
            { positie: "IT", naam: "Ruben Leyssens" },
            { positie: "Vertor", naam: "Wim Nivelle (Wereldkampioen)" },
            { positie: "Sport", naam: "Peter Stinkens" },
            { positie: "Ontspanning", naam: "Thomas Dejan" },
            { positie: "Provisor", naam: "Wout Claes" }
        ]
    },
    {
        jaar: "2003-2004",
        leden: [
            { positie: "Praeses", naam: "Pieter Wuytens (Honoris Causa)" },
            { positie: "Vice-Praeses", naam: "Marco Goossens" },
            { positie: "Quaestor", naam: "Joris Palmans" },
            { positie: "Ab-Actis", naam: "Stefan Van Noppen" },
            { positie: "Schachtenmeester", naam: "Pieter Blommé" },
            { positie: "Schachtenmeester", naam: "Hans Wuytens" },
            { positie: "PR", naam: "Bart de Wit" },
            { positie: "Bacchus", naam: "Chris Geebelen" },
            { positie: "Cantor", naam: "Truus Reynders" },
            { positie: "Scriptor", naam: "Koen Geebelen" },
            { positie: "Scriptor", naam: "Wim Nivelle (Wereldkampioen)" },
            { positie: "Vertor", naam: "Caroline Schalenbourgh" },
            { positie: "Sport", naam: "Melissa Benaets" },
            { positie: "Ontspanning", naam: "Rob Vanderhenst" },
            { positie: "Provisor", naam: "Sofie Vanvoorde" }
        ]
    },
    {
        jaar: "2002-2003",
        leden: [
            { positie: "Praeses", naam: "Stijn Croonenborghs (Stem)" },
            { positie: "Vice-Praeses", naam: "Toon Geboers" },
            { positie: "Quaestor", naam: "Sarah Denis" },
            { positie: "Ab-Actis", naam: "Kurt Lemmens" },
            { positie: "Schachtenmeester", naam: "Pieter Wuytens (Honoris Causa)" },
            { positie: "Schachtenmeester", naam: "Kris Verjans (Klenne)" },
            { positie: "PR", naam: "Chris Geebelen" },
            { positie: "PR", naam: "Ben Nivelle" },
            { positie: "Bacchus", naam: "Joris Palmans" },
            { positie: "Cantor", naam: "Truus Reynders" },
            { positie: "Scriptor", naam: "Bart Vandijk" },
            { positie: "Scriptor", naam: "Lode Vanhout" },
            { positie: "Scriptor", naam: "Lesley Lambrechts" },
            { positie: "Vertor", naam: "Stefan Van Noppen" },
            { positie: "Vertor", naam: "Bart Dewit" },
            { positie: "Sport", naam: "Caroline Dreesen" },
            { positie: "Ontspanning", naam: "Pieter Blommé" }
        ]
    },
    {
        jaar: "2001-2002",
        leden: [
            { positie: "Praeses", naam: "Seppe Heyde" },
            { positie: "Vice-Praeses", naam: "Lode Vanhout" },
            { positie: "Quaestor", naam: "Kris Verjans / Veerle Bloemen" },
            { positie: "Ab-Actis", naam: "Veerle Bloemen / Truus Reynders" },
            { positie: "Schachtenmeester", naam: "Stijn Croonenborghs (Stem)" },
            { positie: "Schachtenmeester", naam: "Bart Rutten" },
            { positie: "PR", naam: "Linda Coolen" },
            { positie: "Cantor", naam: "Eline Bijloos" },
            { positie: "Scriptor", naam: "Gwen Benaets" },
            { positie: "Scriptor", naam: "Truus Reynders" },
            { positie: "Vertor", naam: "Lesley Lambrechts" },
            { positie: "Sport", naam: "Stijn Reniers" },
            { positie: "Ontspanning", naam: "Freya Vervoort" },
            { positie: "Provisor", naam: "Linda Coolen" }
        ]
    },
    {
        jaar: "2000-2001",
        leden: [
            { positie: "Praeses", naam: "Bert Theunis (Bèr)" },
            { positie: "Vice-Praeses", naam: "Kevin Martens" },
            { positie: "Quaestor", naam: "Eline Bijloos" },
            { positie: "Ab-Actis", naam: "Niels Mertens" },
            { positie: "Schachtenmeester", naam: "Jeroen Camps" },
            { positie: "Schachtenmeester", naam: "Wim Willekens (Willy)" },
            { positie: "PR", naam: "Seppe Heyde" },
            { positie: "PR", naam: "Joris Leen (Joor)" },
            { positie: "PR", naam: "Tijl Schroeiers" },
            { positie: "Cantor", naam: "Gwen Benaets" },
            { positie: "Scriptor", naam: "Liesbet Vaes (Akkasjou)" },
            { positie: "Scriptor", naam: "Kris Verjans (Klenne)" },
            { positie: "Scriptor", naam: "Koen Voortmans (Mcguyver)" },
            { positie: "Vertor", naam: "Freya Vervoort" },
            { positie: "Vertor", naam: "Lode Vanhout" },
            { positie: "Sport", naam: "Linda Coolen" },
            { positie: "Ontspanning", naam: "Stijn Croonenborghs (Stem)" },
            { positie: "Provisor", naam: "Truus Reynders" }
        ]
    },
    {
        jaar: "1999-2000",
        leden: [
            { positie: "Praeses", naam: "Bert Ceyssens (Cies) (Honoris Causa)" },
            { positie: "Vice-Praeses", naam: "Bert Theunis (Bèr)" },
            { positie: "Quaestor", naam: "Maarten Lambrechts (Martin)" },
            { positie: "Ab-Actis", naam: "Jochen Vaes" },
            { positie: "Schachtenmeester", naam: "Kevin Martens" },
            { positie: "Schachtenmeester", naam: "Jeroen Camps" },
            { positie: "PR", naam: "Joris Leen (Joor)" },
            { positie: "Scriptor", naam: "Joy Bouts" },
            { positie: "Scriptor", naam: "Mimi Willems" },
            { positie: "Vertor", naam: "Kristof Severijns" },
            { positie: "Sport", naam: "Liesbet Vaes (Akkasjou)" },
            { positie: "Ontspanning", naam: "Gwen Benaets" }
        ]
    },
    {
        jaar: "1998-1999",
        leden: [
            { positie: "Praeses", naam: "Jelle Dries (Hype)" },
            { positie: "Vice-Praeses", naam: "Bert Ceyssens (Cies) (Honoris Causa)" },
            { positie: "Quaestor", naam: "David Peeters (ice-P)" },
            { positie: "Ab-Actis", naam: "Hilde Jans" },
            { positie: "Schachtenmeester", naam: "Nico Augustinas" },
            { positie: "Dom Morum", naam: "Karel Gernaey" },
            { positie: "PR", naam: "Danny de Paepe" },
            { positie: "Cantor", naam: "Veerle Bloemen" },
            { positie: "Cantor", naam: "Johan Looffen" },
            { positie: "Vertor", naam: "Raf Groffils" },
            { positie: "Vertor", naam: "Sven Boedrij" },
            { positie: "Sport", naam: "Bert Theunis" },
            { positie: "Ontspanning", naam: "Niet gespecificeerd" } // Ontspanning zonder specifieke naam vermeld
        ]
    },
    {
        jaar: "1997-1998",
        leden: [
            { positie: "Praeses", naam: "Alexander Daenen (Pauli)" },
            { positie: "Vice-Praeses", naam: "Tim Theunis" },
            { positie: "Quaestor", naam: "Robrecht Schots" },
            { positie: "Ab-Actis", naam: "Jelle Dries" },
            { positie: "Schachtenmeester", naam: "David Thijs (Theske)" },
            { positie: "Dom Morum", naam: "Bart Ulenaers" },
            { positie: "PR", naam: "Gunter Willems" },
            { positie: "Scriptor", naam: "Roy Hendrikx" },
            { positie: "Vertor", naam: "Carolien Labeur" },
            { positie: "Vertor", naam: "Hans Claes" },
            { positie: "Sport", naam: "Niet gespecificeerd" } // Sport zonder specifieke naam vermeld
        ]
    },
    {
        jaar: "1996-1997",
        praeses: [
            { positie: "Praeses", naam: "Patrick Martens (Stro)" },
            { positie: "", naam: "Kurt Gielen" },
            { positie: "", naam: "Liesbeth Smets" },
            { positie: "", naam: "Tim Theunis" },
            { positie: "", naam: "Alexander Daenen" },
            { positie: "", naam: "David Thijs" },
            { positie: "", naam: "Wim Lespoix" },
            { positie: "", naam: "Luc Driesen" }
        ]
    },
    {
        jaar: "1993-1994",
        praeses: [
            { positie: "Praeses", naam: "Patrick Drijkoningen" }
        ]
    },
    {
        jaar: "1992-1993",
        leden: [
            { positie: "Praeses", naam: "Dirk Van der Straeten" },
            { positie: "Vice-Praeses", naam: "Pieter-Jan Bijnens" },
            { positie: "Quaestor", naam: "Bert Molenaers" },
            { positie: "Ab-Actis", naam: "Hans Thys" },
            { positie: "Schachtenmeester", naam: "Wim Van Cauwenbergh" },
            { positie: "Dom Morum", naam: "" },
            { positie: "PR", naam: "Patrick Drijkoningen" },
            { positie: "Pro-Cantor", naam: "Guyke Van Baelen" },
            { positie: "Scriptor", naam: "Filip van den Bruel" },
            { positie: "Vertor", naam: "Billy" },
            { positie: "Sport", naam: "Geert Gabriëls" }
        ]
    },
    {
        jaar: "1989-1990",
        leden: [
            { positie: "Praeses", naam: "Tsjerk Boursma" }
        ]
    },
    {
        jaar: "1986-1987",
        praeses: [
            { positie: "Praeses", naam: "Kris Neven" },
            { positie: "Vice-Praeses", naam: "Bart Stevens" },
            { positie: "Ab-Actis", naam: "Erwin Amandels" },
            { positie: "Zedenmeester", naam: "Kris Schuermans" },
            { positie: "", naam: "Berti Broeckx" },
            { positie: "", naam: "Guy Valkeneers" },
            { positie: "", naam: "Lisette Pinxten" }
        ]
    },
    {
        jaar: "1985-1986",
        praeses: [
            { positie: "Praeses", naam: "Guido Weerts" },
            { positie: "Vice-Praeses", naam: "Kris Neven" },
            { positie: "Ab-Actis", naam: "Kris Schuermans" },
            { positie: "Pro-Cantor", naam: "Erwin Amandels" },
            { positie: "", naam: "Marc Valkeneers" },
            { positie: "", naam: "Michel Thonon" },
            { positie: "", naam: "Michel Mykytiuk" },
            { positie: "", naam: "Dirk (?) Bikkenberghs" }
        ]
    },
    {
        jaar: "1984-1985",
        praeses: [
            { positie: "Praeses", naam: "Eddy Nowicki" }
        ]
    },
    {
        jaar: "1982-1983",
        praeses: [
            { positie: "Praeses", naam: "Luc Goovaerts" }
        ]
    },
    {
        jaar: "1981-1982",
        praeses: [
            { positie: "Praeses", naam: "Hans Van Schepdael" }
        ]
    },
    {
        jaar: "1980-1981",
        praeses: [
            { positie: "Praeses", naam: "Gerard Paulussen" }
        ]
    },
    {
        jaar: "1979-1980",
        praeses: [
            { positie: "Praeses", naam: "Hubert Lecoque" },
            { positie: "Secretaris", naam: "Eddy Ketelbuters" },
            { positie: "Sport", naam: "Gerard Paulussen" }
        ]
    },
    {
        jaar: "1978-1979",
        praeses: [
            { positie: "Praeses", naam: "Jef Broux" },
            { positie: "Vice-Praeses", naam: "Guido Hex" }
        ]
    }
];


const OudPraesidium = () => {
    return (
        <div>
  <h1 className="text-4xl mt-40 underline font-bold tracking-tight text-gray-900 sm:text-6xl">
    Oud Praesidia
  </h1>
  <div
    className="absolute inset-0 z-10 transform-gpu overflow-hidden blur-3xl"
    aria-hidden="true"
  >
    <div className="absolute inset-0 bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-20"></div>
  </div>
  <div className="container mx-auto mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
    {bestuursleden.map((bestuur) => (
      <div key={bestuur.jaar} className="my-4 bg-blue-100 p-4 rounded-lg">
        <h1 className="text-gray-500 text-2xl font-bold">{bestuur.jaar}</h1>
        {bestuur.leden && (
          <ul className="ml-4 mt-5">
            {bestuur.leden.map((lid) => (
              <li
                key={lid.naam}
                className="text-yellow-500 text-lg flex justify-between"
              >
                <span>{lid.positie}</span>{" "}
                <span className="text-blue-500">{lid.naam}</span>
              </li>
            ))}
          </ul>
        )}
        {bestuur.praeses && (
          <ul className="ml-4">
            {bestuur.praeses.map((lid) => (
              <li key={lid.naam} className="text-yellow-500 flex justify-between">
                <span>{lid.positie}</span>{" "}
                <span className="text-blue-500">{lid.naam}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    ))}
  </div>
</div>

    );
};

export default OudPraesidium;