

export const AboutImageWallReverse = () => {

    return (
        <div class="relative isolate -z-10">
        <svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true">
            <defs>
                <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1"
                    patternUnits="userSpaceOnUse">
                    <path d="M.5 200V.5H200" fill="none" />
                </pattern>
            </defs>
            <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                    stroke-width="0" />
            </svg>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
        </svg>
        <div class="absolute left-0 right-1/2 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
    aria-hidden="true">
    <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-40"/>
</div>
        <div class="overflow-hidden" data-aos="fade-up" data-aos-delay="200">
            <div class="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                    <div class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0 image-wall reverse">
                        <div
                            class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                            <div class="relative">
                                <img src="/Atmosphere/KerberusSfeer5.png" alt="Image with friend"
                                    class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                                <div
                                    class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                        </div>
                        <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                            <div class="relative">
                                <img src="/logo/Cerberus.webp" alt="Image in Louvre"
                                    class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                                <div
                                    class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                            <div class="relative">
                                <img src="/Atmosphere/KerberusSfeer6.png" alt="Image of me"
                                    class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                                <div
                                    class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                        </div>
                        <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                            <div class="relative">
                                <img src="/Atmosphere/KerberusSfeer7.png" alt=""
                                    class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                                <div
                                    class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                            <div class="relative">
                                <img src="/Atmosphere/KerberusSfeer4.png" alt=""
                                    class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
                                <div
                                    class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                        </div>
                    </div>
                    <div class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                        <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">HET ONSTAAN VAN KERBERUS
                        </h1>
                        <p class="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                        Ergens begin 1975 werden diploma's ingenieur in Europa geharmoniseerd en onstond de opleiding "Industrieel Ingenieur - 4 jaar" in plaats van 'Technisch Ingenieur' dat tot dan toe 3 jaar zwoegen was. 
                        Ook op de nieuwe KIHL werd deze opleiding in het lessenpakket opgenomen. De KIHL was in die tijd een samenraapsel van 3 scholen en locaties: Theorie-lessen op de 7de en 8de etage van de TT-torens in Hasselt praktijk EM in de lokalen van de technische school aan de andere kant van Hasselt praktijk Chemie in Sint-Truiden. 
                        Omdat elk zichzelf-respecterend departement een studentenvereniging met zich verbonden heeft, werd besloten ook voor deze opleiding een vereniging op te richten. Ematec (technische ingenieurs) en Sarchinium (graduaten scheikunde te Velm), werden in 1978 opgedoekt en hieruit verrees Kerberus, de naam en het logo (de hond met de drie koppen) verwezen naar de 3 scholen. 
                        Als kleuren werden blauw en geel gekozen. Onder toezicht van toenmalig directeur Pater Bollen kreeg Kerberus lokaal 69 toegewezen als studentenlokaal. In dit lokaal zijn menig beroemde/beruchte feestjes en cantussen gehouden, maar met de verhuis naar de nieuwe lokalen in Diepenbeek kwam dit tot een eind. 
                        Natuurlijk staat Kerberus Diepenbeek nog altijd garant voor een dik feestje!
                        
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    );
}
