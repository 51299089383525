import ASML from "../components/sponsor-pages/ASML"

export default function Sponsors() {
  return (
    <div>
          <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
          </div>
          <div className="mx-auto max-w-2xl pt-32 scroll-pb-40 sm:pt-48 sm:pb- lg:pt-56">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight underline text-black-700 sm:text-6xl">Onze sponsors</h1>
            </div>
          </div>
          <ASML></ASML>
    </div>
  )
}
