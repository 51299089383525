function openLink(link) {
    window.open(link, '_blank');
}

const SponsorComponent = () => {
    return (
        <div id="sponsors" className="bg-white py-20 sm:py-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                Met dank aan onze sponsors
            </h2>
            <div className="cursor-pointer mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="/logo/ASML.png"
                alt="Transistor"
                width={158}
                height={48}
                onClick={() => openLink('https://www.asml.com/')}
              />
              <img
                className="cursor-pointer col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="/logo/cegeka.png"
                alt="Reform"
                width={158}
                height={48}
                onClick={() => openLink('https://www.cegeka.com/')}
              />
              <img
                className="cursor-pointer col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="/logo/TMC.png"
                alt="Tuple"
                width={158}
                height={48}
                onClick={() => openLink('https://www.tmc.com/')}
              />
              <img
                className="cursor-pointer col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="/logo/VINCI.png"
                alt="SavvyCal"
                width={158}
                height={48}
                onClick={() => openLink('https://www.vinci.com/')}
              />
              <img
                className="cursor-pointer col-span-2 col-start-2 max-h-24 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="/logo/Connect-ways.webp"
                alt="Statamic"
                width={158}
                height={48}
                onClick={() => openLink('https://www.connect-ways.com/')}
              />
            </div>
          </div>
        </div>
      )
    }

export default SponsorComponent;

  