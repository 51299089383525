import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Praesidium from './pages/Praesidium';
import Contact from './pages/Contact';
import OudPraesidium from './pages/OudPraesidium';
import Sponsors from './pages/Sponors';

const Main = () => {
  return (
    <Router>
        <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/praesidium" element={<Praesidium />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>
            <Route exact path="/sponsors" element={<Sponsors />}></Route>
            <Route exact path="/oudPraesidium" element={<OudPraesidium />}></Route>
        </Routes>
    </Router>
    
  );
}

export default Main;