import React from 'react';

const PraesidiumList = () => {
    const images = [
        { src: '/praesidium_23_24/Praeses.webp', name: 'Daryll Geven', function: 'Praeses' },
        { src: '/praesidium_23_24/Vice1.webp', name: 'Bram Kauffman', function: 'Vice-Praeses' },
        { src: '/praesidium_23_24/Vice2.webp', name: 'Keo Hermans', function: 'Vice-Praeses' },
        { src: '/praesidium_23_24/Quastor.webp', name: 'Nona Vanratingen', function: 'Quaestor' },
        { src: '/praesidium_23_24/Ab-Actis.webp', name: 'Brent Willems', function: 'Ab-Actis' },
        { src: '/praesidium_23_24/Schachtenmeester1.webp', name: 'Liesl Stevens', function: 'Schachtenmeester' },
        { src: '/praesidium_23_24/Schachtenmeester2.webp', name: 'Nette Vanderhoydonk', function: 'Schachtenmeester' },
        { src: '/praesidium_23_24/Schachtenmeester3.webp', name: 'Rune Krampiltz', function: 'Schachtenmeester' },
        { src: '/praesidium_23_24/Dux1.webp', name: 'Haakon Crawford', function: 'Dux Festi' },
        { src: '/praesidium_23_24/Dux2.webp', name: 'Steyn Vanderhallen', function: 'Dux Festi' },
        { src: '/praesidium_23_24/PR1.webp', name: 'Jasper Smetsers', function: 'P.R.' },
        { src: '/praesidium_23_24/PR2.webp', name: 'Jasper Serry', function: 'P.R.' },
        { src: '/praesidium_23_24/Media.webp', name: 'Jana Luyckx', function: 'Media' },
        { src: '/praesidium_23_24/Bacchus.webp', name: 'Enzo La Morella', function: 'Bacchus' },
        { src: '/praesidium_23_24/Provisor.webp', name: 'Thibeau Liwerski', function: 'Provisor' },
        { src: '/praesidium_23_24/Sport&Ontspanning.webp', name: 'Dimitri Scheepmans', function: 'Sport & Ontspanning' },
    ];

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-auto px-4 mb-4">
  {images.map((image, index) => (
    <div key={index} className="group relative">
      <img
        src={image.src}
        alt={`Image ${index + 1}`}
        className="w-full h-full object-cover"
      />
      <div
        className={`text-white text-center absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-70 transition-opacity duration-200 flex flex-col items-center justify-center space-y-2`}
      >
        <p className="text-2xl font-extrabold">{image.name}</p>
        <p className="text-xl font-bold bg-yellow-500 p-2 rounded mt-4 text-gray-800">
          {image.function}
        </p>
      </div>
    </div>
  ))}
</div>

    );
};

export default PraesidiumList;