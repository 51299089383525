import { click } from "@testing-library/user-event/dist/click";

const images = [
    { src: '/logo/SummersEnd.png', text: 'Summers End TD 2024', link: 'https://fb.me/e/3ROnmBERR' },
    { src: '/logo/SummersEnd.png', text: 'Summers End TD 2024', link: 'https://fb.me/e/3ROnmBERR' },
    { src: '/logo/SummersEnd.png', text: 'Summers End TD 2024', link: 'https://fb.me/e/3ROnmBERR' }
];

function openLink(link) {
    window.open(link, '_blank');
}

const ActivityCarousel = () => {
    return (
        <div className="bg-white h-82 grid grid-cols-1 sm:grid-cols-3 gap-4 p-4">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="relative group cursor-pointer"
                    onClick={() => openLink(image.link)}
                >
                    <img
                        className="rounded-lg object-cover w-full h-full group-hover:opacity-15"
                        src={image.src}
                        alt={`Image ${index + 1}`}
                    />
                    <p className="absolute inset-0 flex items-center justify-center text-3xl font-extrabold text-black opacity-0 group-hover:opacity-100">
                        {image.text}
                    </p>
                </div>
            ))}
        </div>

    )
}

export default ActivityCarousel;