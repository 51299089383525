import React from 'react';

const IntroMovie = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full h-screen">
            <h1 className="text-4xl font-bold mb-4">Get to know us!</h1>
            <video class="h-3/4 rounded-lg" muted autoPlay controls>
                <source
                    src="/Movies/Kerberus_vibes.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default IntroMovie;