import ActivityCarousel from "../components/Homepage/ActivityCarousel";
import Introduction from "../components/Homepage/Introduction";
import IntroMovie from "../components/Homepage/IntroMovie";

  const Home = () => {
  return (
    <div>
      <Introduction></Introduction>
      <IntroMovie></IntroMovie>
      <ActivityCarousel></ActivityCarousel>
    </div>
    
  )
}

export default Home;