import { AboutImageWall } from "../components/About/AboutImageWall";
import { AboutImageWallReverse } from "../components/About/AboutImageWallReverse";
import { KerberusLied } from "../components/About/KerberusLied";

const About = () => {
    return (
        <div class="bg-white pb-40">
            <div class="relative isolate px-6 pt-14 lg:px-8">
                <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                  <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
                </div>
                
                <AboutImageWall />
                <AboutImageWallReverse></AboutImageWallReverse>
                <KerberusLied></KerberusLied>
                <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                    <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#3d86fd] to-[#ffe942] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
                </div>
            </div>
        </div>
    )
}

export default About;